import React from "react";
// import './Product.css';
import "antd/dist/antd.css";
import { PageHeader, Radio, Input, Menu, Dropdown, Button, Tabs } from "antd";
import { ProductList } from "../../config/Configs.js";
import { VPMap } from "../../config/VPMap";
import ImageGallery from "react-image-gallery";
import { getPlayerApi } from "../../components/Player/module.js";
// import "../components/Gallery/style.scss";
// import "./Products.css";
import { AttrButtons } from "../../components/Jacket";

import ReactGA from "react-ga";

const { Search } = Input;
const { TabPane } = Tabs;

class Shoe extends React.Component {
  componentDidMount() {

    const productId = this.props.match.params.productId;
    const product = ProductList["shoe"];
    console.log(product);

    window
      .threekitPlayer({
        authToken: "c916339c-eb4e-4514-935d-4a622b2cc31e",
        el: document.getElementById("tkplayer"),
        stageId: `${product.scene}`,

        assetId: `${product.threekit}`,
        showAR: false,
        showConfigurator: true,
        initialConfiguration: product.config,
      })
      .then(async (api) => {
        window.player = api;
        window.configurator = await api.getConfigurator();
      });
  }
  render() {
    ReactGA.initialize("UA-63905846-9");
    ReactGA.set({ dimension1: "Fashion" });

    return (
      <div id="content">
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          onBack={() => window.location.assign("/")}
          title={"Shoe Configurator"}
        />

        <div
          id="tkplayer"
          style={{
            height: "50vh",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            background: "#ffffff",
          }}
        ></div>
      </div>
    );
  }
}

export default Shoe;
