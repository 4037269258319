const VPMap = {
    "Black-FloralNavy": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Black-FloralNavy-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Black-FloralNavy-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Black-FloralNavy-roll.jpg"
    },
    "Black-FloralPurple": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Black-FloralPurple-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Black-FloralPurple-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Black-FloralPurple-roll.jpg"
    },
    "Black-NavyWhiteDot": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Black-NavyWhiteDot-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Black-NavyWhiteDot-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Black-NavyWhiteDot-roll.jpg"
    },
    "Black-Surfside": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Black-Surfside-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Black-Surfside-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Black-Surfside-roll.jpg"
    },
    "Black-Taupe": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Black-Taupe-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Black-Taupe-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Black-Taupe.jpg"
    },
    "Black-Surfside": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Black-Surfside-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Black-Surfside-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Black-Surfside-roll.jpg"
    },
    "BrightBlue-FloralNavy": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/BrightBlue-FloralNavy-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/BrightBlue-FloralNavy-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/BrightBlue-FloralNavy-roll.jpg"
    },
    "BrightBlue-FloralPurple": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/BrightBlue-FloralPurple-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/BrightBlue-FloralPurple-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/BrightBlue-FloralPurple-roll.jpg"
    },
    "BrightBlue-NavyWhiteDot": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/BrightBlue-NavyWhiteDot-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/BrightBlue-NavyWhiteDot-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/BrightBlue-NavyWhiteDot-roll.jpg"
    },
    "BrightBlue-Surfside": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/BrightBlue-Surfside-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/BrightBlue-Surfside-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/BrightBlue-Surfside-roll.jpg"
    },
    "BrightBlue-Taupe": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/BrightBlue-Taupe-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/BrightBlue-Taupe-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/BrightBlue-Taupe-roll.jpg"
    },
    "BrightBlue-Surfside": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/BrightBlue-Surfside-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/BrightBlue-Surfside-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/BrightBlue-Surfside-roll.jpg"
    },
    "GreyPlaid-FloralNavy": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/GreyPlaid-FloralNavy-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/GreyPlaid-FloralNavy-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/GreyPlaid-FloralNavy-roll.jpg"
    },
    "GreyPlaid-FloralPurple": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/GreyPlaid-FloralPurple-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/GreyPlaid-FloralPurple-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/GreyPlaid-FloralPurple-roll.jpg"
    },
    "GreyPlaid-NavyWhiteDot": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/GreyPlaid-NavyWhiteDot-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/GreyPlaid-NavyWhiteDot-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/GreyPlaid-NavyWhiteDot-roll.jpg"
    },
    "GreyPlaid-Surfside": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/GreyPlaid-Surfside-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/GreyPlaid-Surfside-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/GreyPlaid-Surfside-roll.jpg"
    },
    "GreyPlaid-Taupe": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/GreyPlaid-Taupe-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/GreyPlaid-Taupe-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/GreyPlaid-Taupe-roll.jpg"
    },
    "GreyPlaid-Surfside": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/GreyPlaid-Surfside-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/GreyPlaid-Surfside-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/GreyPlaid-Surfside-roll.jpg"
    },
    "Sharkskin-FloralNavy": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Sharkskin-FloralNavy-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Sharkskin-FloralNavy-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Sharkskin-FloralNavy-roll.jpg"
    },
    "Sharkskin-FloralPurple": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Sharkskin-FloralPurple-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Sharkskin-FloralPurple-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Sharkskin-FloralPurple-roll.jpg"
    },
    "Sharkskin-NavyWhiteDot": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Sharkskin-NavyWhiteDot-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Sharkskin-NavyWhiteDot-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Sharkskin-NavyWhiteDot-roll.jpg"
    },
    "Sharkskin-Surfside": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Sharkskin-Surfside-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Sharkskin-Surfside-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Sharkskin-Surfside-roll.jpg"
    },
    "Sharkskin-Taupe": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Sharkskin-Taupe-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Sharkskin-Taupe-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Sharkskin-Taupe-roll.jpg"
    },
    "Sharkskin-Surfside": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Sharkskin-Surfside-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Sharkskin-Surfside-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Sharkskin-Surfside-roll.jpg"
    },
    "Wine-FloralNavy": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Wine-FloralNavy-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Wine-FloralNavy-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Wine-FloralNavy-roll.jpg"
    },
    "Wine-FloralPurple": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Wine-FloralPurple-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Wine-FloralPurple-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Wine-FloralPurple-roll.jpg"
    },
    "Wine-NavyWhiteDot": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Wine-NavyWhiteDot-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Wine-NavyWhiteDot-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Wine-NavyWhiteDot-roll.jpg"
    },
    "Wine-Surfside": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Wine-Surfside-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Wine-Surfside-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Wine-Surfside-roll.jpg"
    },
    "Wine-Taupe": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Wine-Taupe-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Wine-Taupe-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Wine-Taupe-roll.jpg"
    },
    "Wine-Surfside": {
        "open": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/open-vest/Wine-Surfside-open.jpg",
        "personal": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/personal-vest/Wine-Surfside-personal.jpg",
        "roll": "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Wine-Surfside-roll.jpg"
    }
};
export {VPMap};