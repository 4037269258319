import React from "react";
import { Radio } from "antd";

export const AttrButtons = props => (
  <div className="btn-container">
    {props.mat_id ? (
      <div>
        <b>{props.mat_id}</b>
      </div>
    ) : (
      <p></p>
    )}

    <div>
      <Radio.Group defaultValue={props.materials[0]}>
        {props.materials.map(val => (
          <Radio.Button
            key={val}
            value={val}
            id={props.mat_id}
            onClick={props.handleJacketChange}
          >
            {val}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
    {props.colors ? (
      <Radio.Group defaultValue={props.colors[0]}>
        {props.color_id ? (
          <div>
            <b>{props.color_id}</b>
          </div>
        ) : (
          <p></p>
        )}
        {props.colors.map(val => (
          <Radio.Button
            key={val}
            value={val}
            id={props.color_id}
            onClick={props.handleJacketChange}
          >
            {val}
          </Radio.Button>
        ))}
      </Radio.Group>
    ) : (
      <p></p>
    )}
  </div>
);
