const ProductList = {
  "1": {
    type: "2D",
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/luxury-goods-quickdemo/rolled-sleeve/Black-Surfside-roll.jpg",
    threekit: "",
    name: "Suit Configurator",
    brand: "Virtual Photographer",
  },
  "2": {
    type: "3D-clara",
    imageURL: "https://i.imgur.com/LXj5Bd7.png",
    threekit: "bb524b02-0a62-45a4-a2f5-c2148819ac9b",
    name: "Watch Configurator",
    brand: "3D Configurator",
    materials: ["Silver", "Rubber", "Silver Gold", "Gold"],
  },
  "3": {
    type: "3D-clara",
    imageURL: "https://i.imgur.com/hsdXSnN.png",
    threekit: "fce0731d-c167-4433-a2d8-cb16c77124cc",
    name: "Jacket Configurator",
    brand: "3D Configurator",
    materials: ["Suede", "Lamb Skin", "Python", "Hair On Calf"],
    colors: ["Jet Black", "French Roast", "Toasted Coconut", "Blanc de Blanc"],
  },
  shoe: {
    type: "3D-threekit",
    imageURL: "https://solutions-engineering.s3.amazonaws.com/quickdemo/fashion/shoe.png",
    threekit: "ca823b8b-13cf-498c-80e2-017bf32086ec",
    scene: "8dd4cc80-6fb0-414a-abf8-1a86df201c0d",
    name: "Shoe Configurator",
    brand: "Build your own running shoe",
    config: {
      
    },
    
  },
  handbag: {
    type: "3D-threekit",
    imageURL: "https://solutions-engineering.s3.amazonaws.com/quickdemo/fashion/purse.png",
    threekit: "ca823b8b-13cf-498c-80e2-017bf32086ec",
    scene: "8dd4cc80-6fb0-414a-abf8-1a86df201c0d",
    name: "Handbag",
    brand: "Personalize the perfect handbag",
    config: {
      
    },
    external: true,
    link: "https://fashion.demo.threekit.com/demo/1"
    
  },
};
export { ProductList };
