import React from "react";
// import './Product.css';
import "antd/dist/antd.css";
import { PageHeader, Radio, Input, Menu, Dropdown, Button, Tabs } from "antd";
import { ProductList } from "../config/Configs.js";
import { VPMap } from "../config/VPMap";
import ImageGallery from "react-image-gallery";
import { getPlayerApi } from "../components/Player/module.js";
import "../components/Gallery/style.scss";
import "./Products.css";
import { AttrButtons } from "../components/Jacket";

import ReactGA from "react-ga";

const { Search } = Input;
const { TabPane } = Tabs;

class Product extends React.Component {
  state = {
    suit: "Black",
    int: "FloralNavy",
    images: [
      {
        original: VPMap["Black" + "-" + "FloralNavy"].open,
        thumbnail: VPMap["Black" + "-" + "FloralNavy"].open,
      },
      {
        original: VPMap["Black" + "-" + "FloralNavy"].personal,
        thumbnail: VPMap["Black" + "-" + "FloralNavy"].personal,
      },
      {
        original: VPMap["Black" + "-" + "FloralNavy"].roll,
        thumbnail: VPMap["Black" + "-" + "FloralNavy"].roll,
      },
    ],
  };

  componentDidMount() {
    ReactGA.pageview(
      window.location.pathname +
        window.location.search +
        "fashion.threeit.com:" +
        ProductList[this.props.match.params.productId].name
    );

    window.scrollTo(0, 0);

    if (
      ProductList[this.props.match.params.productId].name ==
      "Watch Configurator"
    ) {
      const { api, playerEl } = getPlayerApi("clara-embed");
      api.sceneIO.fetchAndUse(
        ProductList[this.props.match.params.productId].threekit
      );
      ["fullscreen", "home", "vrSettings", "orbit", "pan", "zoom"].map(
        api.player.hideTool
      );
    } else if (
      ProductList[this.props.match.params.productId].name ==
      "Jacket Configurator"
    ) {
      const { api, playerEl } = getPlayerApi("clara-embed");
      api.sceneIO.fetchAndUse(
        ProductList[this.props.match.params.productId].threekit
      );
      api.commands.setCommandOptions("orbit", {
        turnTable: true,
      });
      ["fullscreen", "home", "vrSettings", "orbit", "pan", "zoom"].map(
        api.player.hideTool
      );

      api.on("loaded", () => {
        api.configuration.setConfiguration({
          "Sides Color": "Jet Black",
          "Ribbing Color": "Jet Black",
          "Sleeves Color": "Jet Black",
          "Collar Color": "Jet Black",
          "Body Color": "Jet Black",
          "Zipper Color": "Black",
        });
      });
    }
  }

  setImages() {
    this.setState({
      images: [
        {
          original: VPMap[this.state.suit + "-" + this.state.int].open,
          thumbnail: VPMap[this.state.suit + "-" + this.state.int].open,
        },
        {
          original: VPMap[this.state.suit + "-" + this.state.int].personal,
          thumbnail: VPMap[this.state.suit + "-" + this.state.int].personal,
        },
        {
          original: VPMap[this.state.suit + "-" + this.state.int].roll,
          thumbnail: VPMap[this.state.suit + "-" + this.state.int].roll,
        },
      ],
    });
  }

  handleClick = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {
        this.setImages();
      }
    );
  };

  handleAttrChange = (e) => {
    window.api.configuration.setConfiguration({ Band: e.target.value });
  };

  handleJacketChange = (e) => {
    window.api.configuration.setConfiguration({
      [e.target.id]: e.target.value,
    });
    console.log([e.target.id] + e.target.value);
  };

  render() {
    ReactGA.initialize("UA-63905846-9");
    ReactGA.set({ dimension1: "Fashion" });

    const productId = this.props.match.params.productId;
    const product = ProductList[productId];

    return (
      <div id="content">
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          onBack={() => window.location.assign("/")}
          title={product.name}
        />

        {product.type == "3D-clara" ? (
          
          <div className="tk-container">
            <div
              id="clara-embed"
              className="clara-embed"
              style={{
                height: 400,
                width: "96%",
                marginLeft: "auto",
                marginRight: "auto",
                background: "#F0F2F5",
              }}
            ></div>
            {product.name == "Jacket Configurator" ? (
              <div>
                <p>Jacket Options</p>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Body" key="1">
                    <div>
                      <AttrButtons
                        handleJacketChange={this.handleJacketChange}
                        materials={product.materials}
                        colors={product.colors}
                        mat_id={"Body Material"}
                        color_id={"Body Color"}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="Sides" key="2">
                    <AttrButtons
                      handleJacketChange={this.handleJacketChange}
                      materials={product.materials}
                      colors={product.colors}
                      mat_id={"Sides Material"}
                      color_id={"Sides Color"}
                    />
                  </TabPane>
                  <TabPane tab="Sleeves" key="3">
                    <AttrButtons
                      handleJacketChange={this.handleJacketChange}
                      materials={product.materials}
                      colors={product.colors}
                      mat_id={"Sleeves Material"}
                      color_id={"Sleeves Color"}
                    />
                  </TabPane>
                </Tabs>
              </div>
            ) : (
              <p></p>
            )}

            {product.name == "Watch Configurator" ? (
              <div>
                <AttrButtons
                  handleJacketChange={this.handleAttrChange}
                  materials={product.materials}
                />
                <div style={{ margin: "10px" }}>
                  <Search
                    placeholder="Engraving"
                    enterButton="Engrave"
                    style={{ width: 200 }}
                    onSearch={(value) =>
                      window.api.configuration.setConfiguration({
                        Engraving: value,
                      })
                    }
                  />
                </div>
              </div>
            ) : (
              <p></p>
            )}
          </div>
        ) : (
          <div>
            <div className="config-container">
              <div className="mtl-btn-container" id="test">
                <b>Suit Material</b>
                <div>
                  <Radio.Group defaultValue="Black">
                    <Radio.Button
                      onClick={this.handleClick}
                      value={"Black"}
                      id="suit"
                    >
                      Black Pindot
                    </Radio.Button>
                    <Radio.Button
                      onClick={this.handleClick}
                      value={"BrightBlue"}
                      id="suit"
                    >
                      Bright Blue
                    </Radio.Button>
                    <Radio.Button
                      onClick={this.handleClick}
                      value={"GreyPlaid"}
                      id="suit"
                    >
                      Grey Plaid
                    </Radio.Button>

                    <Radio.Button
                      onClick={this.handleClick}
                      value={"Sharkskin"}
                      id="suit"
                    >
                      Sharkskin
                    </Radio.Button>
                    <Radio.Button
                      onClick={this.handleClick}
                      value={"Wine"}
                      id="suit"
                    >
                      Wine
                    </Radio.Button>
                  </Radio.Group>
                </div>
              </div>

              <div
                className="int-btn-container"
                style={{ marginBottom: "5px" }}
              >
                <b>Interior Material</b>
                <div>
                  <Radio.Group defaultValue="FloralNavy">
                    <Radio.Button
                      onClick={this.handleClick}
                      value={"FloralNavy"}
                      id="int"
                    >
                      Floral Navy
                    </Radio.Button>
                    <Radio.Button
                      onClick={this.handleClick}
                      value={"FloralPurple"}
                      id="int"
                    >
                      Floral Purple
                    </Radio.Button>
                    <Radio.Button
                      onClick={this.handleClick}
                      value={"NavyWhiteDot"}
                      id="int"
                    >
                      Navy White Dot
                    </Radio.Button>

                    <Radio.Button
                      onClick={this.handleClick}
                      value={"Surfside"}
                      id="int"
                    >
                      Surfside
                    </Radio.Button>
                    <Radio.Button
                      onClick={this.handleClick}
                      value={"Taupe"}
                      id="int"
                    >
                      Taupe
                    </Radio.Button>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <ImageGallery items={this.state.images} showPlayButton={false} />
          </div>
        )}
      </div>
    );
  }
}

export default Product;
