import React from "react";
import "./Product.css";
import "antd/dist/antd.css";
import { Card, List } from "antd";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { ProductList } from "../../config/Configs.js";
import ReactGA from "react-ga";

const productIds = [];
for (var i in ProductList) {
  productIds.push(i);
}

class Products extends React.Component {
  render() {
    ReactGA.initialize("UA-63905846-9");
    ReactGA.set({ dimension1: "Fashion" });
    ReactGA.pageview(
      window.location.pathname +
        window.location.search +
        "fashion.threeit.com:landing"
    );
    return (
      <div id="product-content">
        <List
          grid={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 5 }}
          dataSource={productIds}
          renderItem={(item) =>
            ProductList[item].external != true ? (
              <Link
                to={
                  ProductList[item].external == true
                    ? ProductList[item].link
                    : "/demo/" + item
                }
              >
                <List.Item>
                  <Card
                    cover={
                      <img
                        style={{
                          maxHeight: 200,
                          width: isMobile ? "auto" : "auto",
                          margin: "auto",
                          // marginRight: "auto"
                        }}
                        src={ProductList[item].imageURL}
                      ></img>
                    }
                  >
                    <Card.Meta
                      title={ProductList[item].name}
                      description={ProductList[item].brand}
                    />
                  </Card>
                </List.Item>
              </Link>
            ) : (
              <a href={ProductList[item].link} target="_blank">
              <List.Item>
                <Card
                  cover={
                    <img
                      style={{
                        maxHeight: 200,
                        width: isMobile ? "auto" : "auto",
                        margin: "auto",
                        // marginRight: "auto"
                      }}
                      src={ProductList[item].imageURL}
                    ></img>
                  }
                >
                  <Card.Meta
                    title={ProductList[item].name}
                    description={ProductList[item].brand}
                  />
                </Card>
              </List.Item>
              </a>
            )
          }
        ></List>
      </div>
    );
  }
}

export default Products;
