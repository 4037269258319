export const getPlayerApi = playerClass => {
    // const playerEl = document.createElement("div");
    // playerEl.style.cssText = 'height:400px;'
    // playerEl.className = "player-el";
    // document.getElementById("root").appendChild(playerEl);
  
    // Assign API to window for console debugging
    const api = window.claraplayer(playerClass);
    window.api = api;
  
    return { playerEl: playerClass, api: api };
  };
  
  export default { getPlayerApi };